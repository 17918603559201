/*
  import { reserveStore } from '@/services/ReserveFundingTarget/store'
*/

import Vue from 'vue'
import Vuex from 'vuex'
import _get from 'lodash/get'
import { notifyProblem, notifyError, notifyMessage } from '@/services/notify'
import kms from '@/services/kms'

const isDebug = true

/* eslint-disable no-empty-pattern */
const actions = {
  /*
    const { list } = await reserveStore.dispatch ('getByHoaId', {
      hoaId
    });
  */

  async getByHoaId({}, { hoaId, fiscalYear = null }) {
    try {
      console.debug('in getByHoaId...')

      let path = `/ReserveFundingTargets/ReserveFundingTarget/GetReserveFundingTargetByHoaId?hoaID=${hoaId}`
      if (fiscalYear) {
        path = `/ReserveFundingTargets/ReserveFundingTarget/GetReserveFundingTargetByHoaId?hoaID=${hoaId}&fiscalYear=${fiscalYear}`
      }

      const result = await kms.get(path)

      if (isDebug == true) console.debug('getByHoaId=' + JSON.stringify(result))

      return {
        result: result
      }
    } catch (exception) {
      console.debug(`There was a problem retrieving the reserve funding target.`)
      console.error(exception)
    }

    return {
      result: {}
    }
  },

  async listReserveFundingTarget({}, { hoaId }) {
    try {
      console.debug('in listReserveFundingTarget...')
      const results = await kms.get(
        `/ReserveFundingTargets/ReserveFundingTarget/List?hoaID=${hoaId}`
      )

      if (isDebug == true) console.debug('listReserveFundingTarget=' + JSON.stringify(results))

      return {
        list: results.results
      }
    } catch (exception) {
      console.debug(`There was a problem retrieving the reserve funding target list.`)
      console.error(exception)
    }

    return {
      list: []
    }
  },

  async addReserveFundingTarget({ dispatch }, { hoaID, fiscalYear, targetAmount, done }) {
    if (isDebug == true) console.debug('...in addReserveFundingTarget')
    try {
      const results = await kms.post(`/ReserveFundingTargets/ReserveFundingTarget`, {
        hoaID,
        fiscalYear,
        targetAmount
      })

      if (this.isDebug == true) console.debug('results=' + JSON.stringify(results))

      if (dispatch && dispatch != undefined) console.debug(dispatch)

      if (results.reserveFundingTargetID > 0) {
        notifyMessage(`Successfully added your reserve funding target.`)
        done()
      } else {
        notifyError('There was a problem adding your reserve funding target.')
      }
    } catch (exception) {
      notifyProblem(`There was a problem adding your reserve funding target.`)
      console.error(exception)
    }
  },

  async deleteReserveFunding({ dispatch }, { reserveFundingTargetID, done }) {
    try {
      const path = `/ReserveFundingTargets/ReserveFundingTarget/${reserveFundingTargetID}?forceDelete=false`

      const results = await kms.delete(path)

      if (isDebug == true) console.debug('results=' + JSON.stringify(results))

      if (dispatch && dispatch != undefined) console.debug(dispatch)

      const wasDeleted = _get(results, 'deleted', false)

      if (wasDeleted === true) {
        notifyMessage(`Successfully deleted this reserve target.`)
        done()
      } else {
        notifyError('There was a problem deleting this reserve target.')
      }
    } catch (exception) {
      notifyProblem(`There was a problem deleting this reserve target.`)
      console.error(exception)
    }
  },

  async updateReserveFunding({ dispatch }, { payload = {}, done }) {
    console.debug('updateReserveFunding=' + JSON.stringify(payload))

    try {
      const results = await kms.put(`/ReserveFundingTargets/ReserveFundingTarget`, payload)

      if (this.isDebug == true) console.debug('results=' + JSON.stringify(results))

      if (dispatch && dispatch != undefined) console.debug(dispatch)

      if (results.reserveFundingTargetID > 0) {
        notifyMessage(`Successfully updated the reserve funding targets.`)
        done()
      } else {
        notifyError('There was a problem updating the reserve funding targets')
      }
    } catch (exception) {
      notifyProblem(`There was a problem processing the update for the reserve funding targets.`)
      console.error(exception)
    }
  }
}
/* eslint-enable no-empty-pattern */

Vue.use(Vuex)
export const reserveStore = new Vuex.Store({
  state: {},
  mutations: {},
  getters: {},
  actions
})
