<template>
  <PageContent :title="$t('title')" main-dashboard>
    <div class="section about has-text-centered" tests-id="management-dashboard">
      <div class="container">
        <div class="flex flex-wrap -mx-2" :style="styles.tilesContainer">
          <ActionItemCard :data="receivable" :styles="cardStyles" />
          <FinancialOverviewCard :data="financial" :styles="cardStyles" />
          <AccountsPayableCard :data="payable" :styles="cardStyles" />
          <CommunityCenterCard :data="community" :styles="cardStyles" />
          <ComplianceCard :data="compliance" :styles="cardStyles" />
          <ArchitecturalControlWorkOrderCard :data="architecturalworkorder" :styles="cardStyles" />
          <MeetingSummaryCard :data="meetingsummary" :styles="cardStyles" />
        </div>
      </div>
    </div>
  </PageContent>
</template>

<script type="es6">
import { mapGetters, mapState } from 'vuex'
import PageContent from '@/containers/PageContent'
import { twoFactorAuthentication } from '@/services/TwoFactorAuthentication/store'
import { actionItemStore } from '@/services/BoardActionItems/store'
import { apStatisticsStore } from '@/services/ApStatistics/store'
import { listViolations } from '@/services/ViolationIssue/Violation/List'
import { getAReqsList } from '@/services/Architectural/ArchitecturalSubmission/List'
import { workOrderList } from '@/services/WorkOrders/store'
import { reserveStore } from '@/services/ReserveFundingTarget/store'
import { listBoardPackages } from '@/services/BoardMeetings/BoardPackage/List'
import { parseDate } from '@/utilities/Date/parse'
import _get from 'lodash/get'

// Components
import ActionItemCard from '@/components/cards/ActionItemCard'
import FinancialOverviewCard from '@/components/cards/FinancialOverviewCard'
import AccountsPayableCard from '@/components/cards/AccountsPayableCard'
import CommunityCenterCard from '@/components/cards/CommunityCenterCard'
import ComplianceCard from '@/components/cards/ComplianceCard'
import ArchitecturalControlWorkOrderCard from '@/components/cards/ArchitecturalControlWorkOrderCard'
import MeetingSummaryCard from '@/components/cards/MeetingSummaryCard'
import { getWorkOrderReqsList } from '@/services/WorkOrderRequests/WorkOrderRequest/List'

import moment from 'moment'

export default {
  name: 'MainDashboard',

  components: {
    ActionItemCard,
    FinancialOverviewCard,
    AccountsPayableCard,
    CommunityCenterCard,
    ComplianceCard,
    ArchitecturalControlWorkOrderCard,
    MeetingSummaryCard,
    PageContent
  },

  data() {
    const today = moment()
    return {
      isDebug: true,
      violationsCount: 0,
      reserveFundingTarget: 0,
      fiscalYear: 1900,
      formatDateFn: function(value) {
        return value && value != undefined
          ? moment(value.toString(), 'YYYY/MM/DD HH:mm:ss')
              .format('MM-DD-YYYY h:mm a')
              .toString()
          : ''
      },
      aprows: [],
      styles: {
        tilesContainer: {
          display: "flex",
          "flex-direction": "row",
          "flex-wrap": "wrap",
          width: "100%"
        }
      },
      receivable: {
        icon: 'maincheck',
        title: 'Action Items',
        subtitle: 'as of ' + today
            .format('MM-DD-YYYY  h:mm a')
            .toString(),
        isAlternatingRowColors: true,
        list: [],
        hrefList: [],
        button: {
          title: 'View Action Items',
          path: '/actionItems'
        }
      },
      financial: {
        icon: 'dollarsign',
        title: 'Financial Overview',
        subtitle: '',
        financialSummary: {
          reserveTarget: 0,
          reserveAccounts: 0,
          operatingAccount: 0,
          fiscalYear: 1900,
        },
        isAlternatingRowColors: true,
        hrefList: [],
        list: [],
        button: {
          title: 'View Bank Account Details',
          path: '/bankAccounts'
        }
      },
      payable: {
        icon: 'accountspayable',
        title: 'Accounts Payable',
        subtitle: 'Statistics calculated ' + today
            .format('MM-DD-YYYY')
            .toString(),
        isAlternatingRowColors: true,
        hrefList: [
            { prefix: '0', title: 'Invoices received within last 30 days', path: '/ap-invoicesthirtydays/thirty' },
            { prefix: '0', title: 'Invoices pending Coding', path: '/ap-invoicespendingcoding/pending' },
            { prefix: '0', title: 'Approved invoices Pending Board Approval', path: '/ap-invoicespendingapproval/approval' },
            { prefix: '0', title: 'Approved Invoices Pending Posting', path: '/ap-invoicespendingposting/post' }
        ],
        list: [],
        button: {
        }
      },
      community: {
        icon: 'house3',
        title: 'Community Info',
        subtitle: 'as of ' + today
            .format('MM-DD-YYYY  h:mm a')
            .toString(),
        isAlternatingRowColors: true,
        hrefList: [
            { title: 'Governing / Community Documents', path: '/communityCenter/documentCenter#tab' },
            { title: 'Calendar', path: '/communityCenter/calendar#tab' },
            { title: 'Contacts', path: '/hoaContacts#tab' },
        ],
        list: []
      },
      compliance: {
        icon: 'compliance',
        title: 'Compliance',
        subtitle: 'as of ' + today
            .format('MM-DD-YYYY  h:mm a')
            .toString(),
        isAlternatingRowColors: true,
        hrefList: [
          { prefix: `${this.violationsCount | 0}`, title: 'Open Violations', path: '/violations' },
        ],
        list: [],
        button: {
          title: 'Create a Violation',
          path: '/violations'
        }
      },
      architecturalworkorder: {
        icon: 'architectural',
        title: 'Architectural Control',
        subtitle: 'as of ' + today
            .format('MM-DD-YYYY  h:mm a')
            .toString(),
        isAlternatingRowColors: true,
        hrefList: [
          { prefix: `0`, title: 'New Requests', path: '/architecturalRequests' },
        ],
        list: [],
        icon2: 'tools',
        title2: 'Work Orders',
        subtitle2: 'as of ' + today
            .format('MM-DD-YYYY  h:mm a')
            .toString(),
        hrefList2: [
          { prefix: `0`, title: 'Open Work Orders', path: '/workOrders' },
          { prefix: `0`, title: 'Open Work Order Requests', path: '/workOrders' },
        ],
        list2: []
      },
      meetingsummary: {
        icon: 'meetingsummary',
        title: 'Your Meetings and Your Communications Summary',
        subtitle: '',
        isAlternatingRowColors: true,
        hrefList: [],
        list: [],
        button: {
          title: 'Create Board Package',
          path: '/boardMeetings/list#tab'
        }
      },
    }
  },
  async created() {
    const fromSignIn = this.$route.query.fromsignin ? true : false

    if(fromSignIn)
    {
      const returned = await twoFactorAuthentication
      .dispatch('checkFirstUseMultiFactor')

      if(this.isDebug === true) console.debug('isPromptFirstLogin=' + returned.result )

      if(returned.result === false)
      {
        this.$router.push({
          name: 'promptmultifactor'
        })
      }
    }

    const loadingComponent = this.$buefy.loading.open({
      container: null
    })

    try {
      await this.loadReserveFundingTarget()
      await this.loadAssetTotals()
      await this.loadActionItems()
      await this.computeApTotalsFromList()
      await this.loadViolationsCount()
      await this.loadArList()
      await this.loadWorkOrders()
      await this.loadWorkOrderRequests()
      await this.loadBoardMeetings()
    } catch(e) {
      loadingComponent.close()
    }

    loadingComponent.close()
  },
  methods: {
    async loadReserveFundingTarget()
    {
      const { result } = await reserveStore.dispatch ('getByHoaId', {
        hoaId: this.currentHoaId,
      });

      if(result)
      {
        console.debug('reserve funding target=' + JSON.stringify(result))
        this.reserveFundingTarget = result.targetAmount
        this.fiscalYear = result.fiscalYear
      }
    },

    async loadActionItems()
    {
      await actionItemStore
        .dispatch('getActionItemList', {
          hoaID: this.currentHoaId,
          sourceBoardPackageID: null
        })
        .then(({ list }) => {
          if(list)
          {
            let actionItemList = []
            Array.from(list).forEach(e => {
              if(e)
              {
                actionItemList.push({ prefix: '', title: _get(e, 'title', ''), path: `actionItemTabs/${_get(e, 'actionItemID', '')}/true/actionItemDetails#tab` })
              }
            })
            this.receivable.hrefList = actionItemList
          }
        })
    },

    async loadViolationsCount()
    {
      const { successful, list } = await listViolations({
        hoaID: this.currentHoaId
      })
      if (!successful) {
        return
      }

      console.debug('loadViolationsCount=' + list.length)

      this.violationsCount = list.length
      this.compliance.hrefList[0].prefix = this.violationsCount
    },

    async loadArList()
    {
      const { list: archReqs, successful, message } = await getAReqsList({
        params: {
          hoaID: this.currentHoaId,
          pendingStatus: true
        }
      })
      if (!successful) {
        console.error(message)
        return
      }

      this.architecturalworkorder.hrefList[0].prefix = archReqs.length
    },

    async loadWorkOrders()
    {
      const { list } = await workOrderList.dispatch ('getWorkOrderList', {
        hoaId: this.currentHoaId
      });

      if(list)
      {
        const openWorkOrderList = list.filter(f => f.completionDate == null)
        this.architecturalworkorder.hrefList2[0].prefix = openWorkOrderList.length
      }
    },

    async loadWorkOrderRequests()
    {

      const { list, message, successful } = await getWorkOrderReqsList({
        params: {
          hoaId: this.currentHoaId
        }
      })
      if (!successful) {
        console.debug(message)
        return
      }

      console.debug('work order request list=' + JSON.stringify(list))

      const openWorkOrderRequestList = list.filter(f => f.completedDate == null)
      this.architecturalworkorder.hrefList2[1].prefix = openWorkOrderRequestList.length

    },

    async loadAssetTotals()
    {
      await this.$store.dispatch('accounting/getAssetAccountBalanceList', {

      }).then(() => {
        console.log('then...' + JSON.stringify(this.assetAccountBalances))
        if (this.assetAccountBalances)
        {
          Array.from(this.assetAccountBalances).forEach(e => {
            if(e)
            {
              console.log('e=' + JSON.stringify(e))

              if(e.fundType === 'Operating')
              {
                this.financial.financialSummary.operatingAccount = e.balanceAmount
                console.debug('operating=' + this.financial.financialSummary.operatingAccount)
              }
              else if(e.fundType === 'Reserves')
              {
                this.financial.financialSummary.reserveAccounts = e.balanceAmount
                console.debug('reserveAccounts=' + this.financial.financialSummary.reserveAccounts)
                this.financial.financialSummary.reserveTarget = this.reserveFundingTarget
                this.financial.financialSummary.fiscalYear = this.fiscalYear
                console.debug('reserveTarget=' + this.financial.financialSummary.reserveTarget + ', FY=' + this.fiscalYear)
              }
            }
          })
        }
      })
    },

    async computeApTotalsFromList()
    {
      await apStatisticsStore.dispatch ('getApStatistics', {
        hoaID: this.currentHoaId
      }).then(({result}) => {
        console.debug('getApStatistics=' + JSON.stringify(result))
        if(result)
        {
          if(result.numInvoicesReceivedLast30Days)
          {
            this.payable.hrefList[0].prefix = result.numInvoicesReceivedLast30Days
          }

          if(result.numInvoicesPendingCoding)
          {
            this.payable.hrefList[1].prefix = result.numInvoicesPendingCoding
          }

          if(result.numInvoicesPendingApproval)
          {
            this.payable.hrefList[2].prefix = result.numInvoicesPendingApproval
          }

          if(result.numInvoicesPendingPosting)
          {
            this.payable.hrefList[3].prefix = result.numInvoicesPendingPosting
          }
        }
      })
    },

    async loadBoardMeetings() {
      const { list, successful, message } = await listBoardPackages({
        params: {
          hoaID: this.currentHoaId,
          numberOfMonthsBack: 1
        }
      })
      if (!successful) {
        console.debug(message)
        return
      }

      let boardMeetingList = []
      if(list)
      {
        Array.from(list).forEach(e => {
          if(e)
          {
            const id = parseInt(_get(e, 'boardPackageID', ''))
            const tempDate = _get(e, 'meetingDate', '')
            const meetingDate = tempDate ? parseDate(tempDate, 'LL') : 'No Meeting Date'
            const tempMonth = _get(e, 'financialMonth', '')
            const financialMonth = tempMonth ? `${((meetingDate  && meetingDate.length > 0) ? ', ' : '')}Financial Month-${parseDate(tempMonth, 'MMMM YYYY')}` : ''
            const meetingType = `${financialMonth && financialMonth.length > 0 ? ', ' : ''}Type-${_get(e, 'type', '')}`
            const tempTime = _get(e, 'meetingDate', '')
            const time = tempTime ? `${meetingType && meetingType.length > 0 ? ' ' : ''}Time-${parseDate(tempTime, 'h:mm:ss a')}` : ''

            boardMeetingList.push({ prefix: '', title: `${meetingDate}${financialMonth}${meetingType}${time}`, path: `boardMeetingDetails/${id}/detail?mode=open#tab` })
          }
        })
      }

      this.meetingsummary.hrefList = boardMeetingList
    }
  },

  computed: {
    ...mapGetters('user', ['authUser']),
    ...mapGetters('accounting', ['assetAccountBalances']),
    ...mapState({
      breakpoint: state => state.win.breakpoint
    }),
    ...mapGetters('user', ['currentHoaId']),
    cardStyles () {
      return {
        innerTile: {
          ...(this.breakpoint === 'mobile' ? {
            padding: '12px'
          } : {})
        }
      }
    }
  },

  i18n: {
    messages: {
      en: {
        title: 'Main'
      }
    }
  }
}
</script>

<style></style>
