<template>
  <div :style="styles_.tile" class="tile is-parent is-vertical w-full lg:w-1/3 p-2">
    <div :style="styles_.innerTile" class="tile is-child bg-white p-10 rounded shadow">
      <div
        class="rounded-full w-16 h-16 flex items-center justify-center border-4 border-gray-200 bg-blue-200 mx-auto mb-4"
      >
        <i style="width: 60px; height: 60px;" :class="`icon-${data.icon}`" />
      </div>

      <h1 class="text-base text-xl text-blue-800 font-semibold">{{ data.title }}</h1>
      <h2 class="text-base">{{ data.subtitle }}</h2>

      <ul class="mt-8" v-if="Array.isArray(data.list)">
        <li
          v-for="(item, index) in data.list"
          :key="index"
          class="bg-white odd:bg-gray-200 rounded-sm p-2 flex"
          :style="styles.listItem"
        >
          <div class="w-1/5 text-blue-200 font-bold">{{ item.col1 }}</div>
          <div :style="styles.listItemRight" class="text-left">{{ item.col2 }}</div>
        </li>
      </ul>

      <div
        class="pt-2"
        v-if="data.hrefList && data.hrefList != undefined"
        :style="{ marginLeft: '24px' }"
      >
        <ul v-if="data.isAlternatingRowColors && data.isAlternatingRowColors == true">
          <li
            class="bg-white odd:bg-gray-200 rounded-md p-2 flex"
            style="text-align:left !important;"
            v-for="(item, index) in data.hrefList"
            :key="index"
            aria-role="listitem"
          >
            <div
              class="w-1/6 text-blue-400 font-bold"
              style="text-align: center; vertical-align: center;"
            >
              {{ item.prefix }}
            </div>
            <div :style="styles.listItemRight" class="text-left href-overflow-wrap">
              <router-link :to="item.path">
                {{ item.title }}
              </router-link>
            </div>
          </li>
        </ul>
        <span v-else>
          <li
            style="text-align:left !important;"
            v-for="(item, index) in data.hrefList"
            :key="index"
            aria-role="listitem"
          >
            <router-link :to="item.path" class="font-bold href-overflow-wrap">
              {{ item.title }}
            </router-link>
          </li>
        </span>
      </div>
    </div>
    <div :style="styles_.innerTile" class="tile is-child bg-white p-10 rounded shadow">
      <div
        class="rounded-full w-16 h-16 flex items-center justify-center border-4 border-gray-200 bg-blue-200 mx-auto mb-4"
      >
        <i style="width: 60px; height: 60px;" :class="`icon-${data.icon2}`" />
      </div>

      <h1 class="text-base text-xl text-blue-800 font-semibold">{{ data.title2 }}</h1>
      <h2 class="text-base">{{ data.subtitle2 }}</h2>

      <ul class="mt-8" v-if="Array.isArray(data.list2)">
        <li
          v-for="(item, index) in data.list2"
          :key="index"
          class="bg-white odd:bg-gray-200 rounded-sm p-2 flex"
          :style="styles.listItem"
        >
          <div class="w-1/5 text-blue-200 font-bold">{{ item.col1 }}</div>
          <div :style="styles.listItemRight" class="text-left">{{ item.col2 }}</div>
        </li>
      </ul>

      <div
        class="pt-2"
        v-if="data.hrefList2 && data.hrefList2 != undefined"
        :style="{ marginLeft: '24px' }"
      >
        <ul v-if="data.isAlternatingRowColors && data.isAlternatingRowColors == true">
          <li
            class="bg-white odd:bg-gray-200 rounded-md p-2 flex"
            style="text-align:left !important;"
            v-for="(item, index) in data.hrefList2"
            :key="index"
            aria-role="listitem"
          >
            <div
              class="w-1/6 text-blue-400 font-bold"
              style="text-align: center; vertical-align: center;"
            >
              {{ item.prefix }}
            </div>
            <div :style="styles.listItemRight" class="text-left href-overflow-wrap">
              <router-link :to="item.path">
                {{ item.title }}
              </router-link>
            </div>
          </li>
        </ul>
        <span v-else>
          <li
            style="text-align:left !important;"
            v-for="(item, index) in data.hrefList2"
            :key="index"
            aria-role="listitem"
          >
            <router-link :to="item.path" class="font-bold href-overflow-wrap">
              {{ item.title }}
            </router-link>
          </li>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import _merge from 'lodash/merge'

export default {
  name: 'ArchitecturalControlCard',

  props: {
    styles: {
      type: Object,
      default() {
        return {}
      }
    },

    data: {
      icon: String,
      icon2: String,
      prefix: String,
      title: String,
      title2: String,
      subtitle: String,
      subtitle2: String,
      list: [],
      list2: [],
      hrefList: [],
      hrefList2: [],
      isAlternatingRowColors: false
    }
  },

  data: function() {
    return {
      styles_: _merge(
        {},
        {
          dropdown: {
            position: 'absolute',
            bottom: '2.5rem',
            left: 0,
            width: '100%',
            justifyContent: 'center'
          },
          dropdownButton: {
            display: 'flex',
            'align-items': 'center'
          },
          listItem: {
            'align-items': 'center'
          },
          listItemRight: {
            width: '75%'
          },
          textBubble: {
            top: '20px',
            left: '-78.5px',
            color: '#FFF'
          },
          tile: {},
          innerTile: {
            position: 'relative',
            height: '100%',
            paddingBottom: '7rem'
          },
          upArrow: {
            position: 'absolute',
            top: '-5px',
            left: '50%',

            width: 0,
            height: 0,
            'border-left': '5px solid transparent',
            'border-right': '5px solid transparent',

            'border-bottom': '5px solid #2baae1'
          }
        },
        this.styles
      )
    }
  }
}
</script>
<style scoped></style>
