<template>
  <div class="bg-blue-900 rounded h-10">
    <div
      class="bg-blue-200 flex justify-flex-end align-items-flex-end h-full rounded"
      :style="
        `width:${
          isNaN(numerator) ||
          isNaN(denominator) ||
          ((numerator / denominator) * 100).toFixed(2) > 100
            ? 100
            : ((numerator / denominator) * 100).toFixed(2)
        }%`
      "
    >
      <div
        class="bg-blue-200 border-2 border-white rounded-full h-4 w-4 inline-block -mr-2 -mb-2 relative"
      >
        <div id="to-target" class="bg-blue-200 rounded-full absolute p-2 w-40 text-sm z-10">
          <div class="arrow-up bg-blue-200 text-white" style="color: white !important"></div>
          <span style="color:white; font-weight: bold;"
            >{{ safeCompute() }}% to FY{{ retrieveFy() }} reserve target of
            {{ den | currency }}</span
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'LargeSplitBarCard',

  props: {
    numerator: Number,
    denominator: Number,
    fiscalYear: Number,
    data: {
      progressBar: {
        one: Number,
        two: Number
      },
      num: 0,
      den: 1,
      fy: 1900
    }
  },

  data() {
    return {
      difference: 0
    }
  },

  watch: {
    numerator() {
      this.num = this.numerator
    },
    denominator() {
      this.den = this.denominator
    },
    fiscalYear() {
      this.fy = this.fiscalYear || 0
    }
  },

  created() {
    this.difference =
      ((this.numerator / this.denominator) * 100).toFixed(2) > 100
        ? 100
        : ((this.numerator / this.denominator) * 100).toFixed(2)
  },
  mounted() {
    this.defaultValues()
  },
  methods: {
    safeCompute() {
      if (isNaN(this.numerator) || !this.numerator) {
        this.num = 0
      }

      if (isNaN(this.denominator) || !this.denominator) {
        this.den = 1
      }

      if (isNaN(this.fiscalYear) || !this.fiscalYear) {
        this.fy = 1900
      }

      return ((this.num / this.den) * 100).toFixed(2) > 100
        ? 100
        : ((this.num / this.den) * 100).toFixed(2)
    },

    defaultValues() {
      this.num = !isNaN(this.numerator) && this.numerator ? this.numerator : 0
      this.den = !isNaN(this.denominator) && this.denominator ? this.denominator : 1
      this.fy = (!isNaN(this.fiscalYear) && this.fiscalYear) || 0 ? this.fiscalYear || 0 : 1900
    },
    retrieveFy() {
      try {
        this.fy !== undefined && this.fy ? this.fy : ''
      } catch (error) {
        this.fy = 0
      }
    }
  }
}
</script>

<style lang="scss">
#to-target {
  top: 20px;
  left: -78.5px;

  .arrow-up {
    position: absolute;
    top: -5px;
    left: 50%;

    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;

    border-bottom: 5px solid #2baae1;
  }
}
</style>
