<template>
  <div :style="styles_.tile" class="w-full lg:w-full p-2">
    <div :style="styles_.innerTile" class="bg-white p-10 rounded shadow">
      <div>
        <div class="columns">
          <div
            class="rounded-full w-16 h-16 flex items-center justify-center border-4 border-gray-200 bg-blue-200"
          >
            <i style="width: 60px; height: 60px;" :class="`icon-${data.icon}`" />
          </div>

          <h1
            style="float: left; vertical-align: top;"
            class="pl-3 text-base text-xl text-blue-800 font-semibold"
          >
            {{ data.title }}
          </h1>
          <div class="column is-8 is-right">
            <img
              src="@/assets/images/meetingsummary.png"
              alt="Meeting Summary image"
              style="vertical-align: middle; float: right;"
            />
          </div>
        </div>
        <div class="columns">
          <div
            class="column is-4"
            v-if="data.hrefList && data.hrefList != undefined"
            :style="{ marginLeft: '24px', marginTop: '-150px' }"
          >
            <ul v-if="data.isAlternatingRowColors && data.isAlternatingRowColors == true">
              <li
                class="bg-white odd:bg-gray-200 rounded-md p-2 flex"
                style="text-align:left !important;"
                v-for="(item, index) in data.hrefList"
                :key="index"
                aria-role="listitem"
              >
                <div
                  v-if="item.prefix && item.prefix.length > 0"
                  class="w-1/6 text-blue-400 font-bold"
                  style="text-align: left; vertical-align: center;"
                >
                  {{ item.prefix }}
                </div>
                <div :style="styles.listItemRight" class="text-left href-overflow-wrap">
                  <router-link :to="item.path" style="text-align: left !important;">
                    {{ item.title }}
                  </router-link>
                </div>
              </li>
            </ul>
            <span class="column" v-else>
              <li
                style="text-align:left !important;"
                v-for="(item, index) in data.hrefList"
                :key="index"
                aria-role="listitem"
              >
                <router-link :to="item.path" class="font-bold href-overflow-wrap">
                  {{ item.title }}
                </router-link>
              </li>
            </span>
          </div>
          <div class="column is-2 is-left" :style="styles_.dropdown" v-if="data.button">
            <b-dropdown
              :style="{ marginTop: '-127px' }"
              aria-role="list"
              class="outline-none focus:border-none"
            >
              <button
                class="rounded-sm border border-gray-400"
                :style="styles.dropdownButton"
                slot="trigger"
                slot-scope="{ active }"
              >
                <span
                  v-if="
                    data.button.dropdown &&
                      data.button.dropdown != undefined &&
                      data.button.dropdown.length > 0
                  "
                  class="relative"
                  ><b-icon :icon="active ? 'menu-up' : 'menu-down'"></b-icon
                ></span>

                <router-link
                  class="button is-info is-light is-small is-outline is-rounded"
                  :to="data.button.path"
                  >{{ data.button.title }}</router-link
                >
              </button>
            </b-dropdown>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import _merge from 'lodash/merge'

export default {
  name: 'MeetingSummaryCard',

  props: {
    styles: {
      type: Object,
      default() {
        return {}
      }
    },

    data: {
      icon: String,
      prefix: String,
      title: String,
      subtitle: String,
      list: [],
      hrefList: [],
      isAlternatingRowColors: false,
      button: {
        title: String,
        path: String,
        dropdown: []
      }
    }
  },

  data: function() {
    return {
      styles_: _merge(
        {},
        {
          dropdown: {
            position: 'absolute',
            bottom: '2.5rem',
            left: 0,
            width: '100%',
            justifyContent: 'center',
            marginTop: '-110px'
          },
          dropdownButton: {
            display: 'flex',
            'align-items': 'center'
          },
          listItem: {
            'align-items': 'center'
          },
          listItemRight: {
            width: '75%',
            textAlign: 'left'
          },
          textBubble: {
            top: '20px',
            left: '-78.5px',
            color: '#FFF'
          },
          tile: {},
          innerTile: {
            position: 'relative',
            height: '100%',
            paddingBottom: '7rem'
          },
          upArrow: {
            position: 'absolute',
            top: '-5px',
            left: '50%',

            width: 0,
            height: 0,
            'border-left': '5px solid transparent',
            'border-right': '5px solid transparent',

            'border-bottom': '5px solid #2baae1'
          }
        },
        this.styles
      )
    }
  }
}
</script>
<style scoped></style>
